export enum EContainerType {
  CHANNEL = 'CHANNEL',
  SHOW_EXTRA = 'SHOW_EXTRA',
  CAROUSEL = 'CAROUSEL',
  SHOW_CAROUSEL = 'SHOW_CAROUSEL',
  VIDEO_CAROUSEL = 'VIDEO_CAROUSEL',
  PERSONALIZED_FEED = 'PERSONALIZED_FEED',
  SOURCE_FEED = 'SOURCE_FEED',
  SUBJECT_PRIMARY_FEED = 'SUBJECT_PRIMARY_FEED',
  SUBJECT_SECONDARY_FEED = 'SUBJECT_SECONDARY_FEED',
  PAGE = 'PAGE',
  VIDEO_SHOW = 'VIDEO_SHOW',
  VIDEO_SEASON = 'VIDEO_SEASON',
  TEMPLATE = 'TEMPLATE',
}

export enum EContainerTypology {
  CAROUSEL = 'CAROUSEL',
  PERSONALIZED_FEED = 'PERSONALIZED_FEED',
  SOURCE_FEED = 'SOURCE_FEED',
  STANDARD_FEED = 'STANDARD_FEED',
  SUBJECT_PRIMARY_FEED = 'SUBJECT_PRIMARY_FEED',
  SUBJECT_SECONDARY_FEED = 'SUBJECT_SECONDARY_FEED',
  CHANNEL = 'CHANNEL',
  PAGE = 'PAGE',
  VIDEO_SHOW = 'VIDEO_SHOW',
  VIDEO_SEASON = 'VIDEO_SEASON',
  TEMPLATE = 'TEMPLATE',
}

export enum ESlotConfig {
  STANDARD = 'StandardConfig',
  RECOMMENDATION = 'RecommendationConfig',
  IDENTIFIER = 'IdentifierConfig',
  PROGRESS = 'ProgressConfig',
  RESUMEREADING = 'ResumeReadingConfig',
  RECOMMENDATION_CAROUSEL = 'CarouselRecommendationConfig',
  VISUAL_ENTERTAINMENT = 'VisualEntertainmentConfig',
}

export enum ESlotSortByConfig {
  DATE_DESC = 'DATE_DESC',
  NAME_ASC = 'NAME_ASC',
}

export enum EConfigSource {
  PFU = 'PFU',
}

export enum EContainerState {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
  INCOMPLETE = 'INCOMPLETE',
  ERROR = 'ERROR',
}

export enum EContainerRecommendableState {
  TRUE = 'ACTIVE',
  FALSE = 'INACTIVE',
}

export enum EContainerSubTypePage {
  HOME = 'HOME',
  EVENT = 'EVENT',
  GENRE = 'GENRE',
  SPECIAL = 'SPECIAL',
  THEME = 'THEME',
}

export enum EContainerSubTypologyPage {
  HOME = 'HOME',
  EVENT = 'EVENT',
  GENRE = 'GENRE',
  SPECIAL = 'SPECIAL',
  THEME = 'THEME',
}

export enum EContainerSubTypeCarousel {
  COMBO = 'COMBO',
  HERO = 'HERO',
  LIST = 'LIST',
  MOSAIC = 'MOSAIC',
  STANDARD = 'STANDARD',
}

export enum EContainerSubTypologyCarousel {
  COMBO = 'COMBO',
  HERO = 'HERO',
  LIST = 'LIST',
  MOSAIC = 'MOSAIC',
  STANDARD = 'STANDARD',
  REGIONALIZED = 'REGIONALIZED',
}

export enum EContainerSlotType {
  CONTAINER = 'CONTAINER',
  CONTENT = 'CONTENT',
}

export enum ESlotPosition {
  FIXED = 'FIXED',
  RECURRING = 'RECURRING',
}

export enum EContainerCreateOptions {
  SHOW_CAROUSEL = 'SHOW_CAROUSEL',
  VIDEO_CAROUSEL = 'VIDEO_CAROUSEL',
  CAROUSEL = 'CAROUSEL',
  TEMPLATE = 'TEMPLATE',
}

export enum EQueryTypology {
  AUDIO = 'AUDIO',
  AUDIO_BONUS = 'AUDIO_BONUS',
  AUDIO_EPISODE = 'AUDIO_EPISODE',
  AUDIO_TRAILER = 'AUDIO_TRAILER',
  AUDIO_SEGMENT = 'AUDIO_SEGMENT',
  AUDIO_SHOW = 'AUDIO_SHOW',
  AUDIO_UNCUT = 'AUDIO_UNCUT',
  STORY = 'STORY',
  STORY_RECIPE = 'STORY_RECIPE',
  VIDEO_CAPSULE = 'VIDEO_CAPSULE',
  VIDEO_CLIP = 'VIDEO_CLIP',
  VIDEO_DOCUMENTARY = 'VIDEO_DOCUMENTARY',
  VIDEO_EPISODE = 'VIDEO_EPISODE',
  VIDEO_EXCLUSIVE = 'VIDEO_EXCLUSIVE',
  VIDEO_EXCLUSIVITY = 'VIDEO_EXCLUSIVITY',
  VIDEO_MISCELLANEOUS = 'VIDEO_MISCELLANEOUS',
  VIDEO_MOVIE = 'VIDEO_MOVIE',
  VIDEO_PERFORMANCE = 'VIDEO_PERFORMANCE',
  VIDEO_STREAM = 'VIDEO_STREAM',
  VIDEO_TRAILER = 'VIDEO_TRAILER',
  VIDEO_WEB_SERIES = 'VIDEO_WEB_SERIES',
  VIDEO_EDITORIAL = 'VIDEO_EDITORIAL',
  VIDEO_INTERVIEW = 'VIDEO_INTERVIEW',
  VIDEO_SEGMENT = 'VIDEO_SEGMENT',
  VIDEO_SEASON = 'VIDEO_SEASON',
  VIDEO_SHOW = 'VIDEO_SHOW',
  VIDEO_COMING_SOON = 'VIDEO_COMING_SOON',
  MOVIE = 'MOVIE',
  PERFORMANCE = 'PERFORMANCE',
  DOCUMENTARY = 'DOCUMENTARY',
  TOPIC = 'TOPIC',
}

export enum EDays {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
}
